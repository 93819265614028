import Client from "./client";
import Color from "./color";
import Product from "./product";
import Size from "./size";
import Variant from "./variant";

export interface StoreSaleItem {
  id?: number;
  companyId?: number;
  storeSaleId?: number;
  name?: string;
  clothingProductId?: number;
  clothingProduct?: Product;
  productVariantId?: number;
  productVariant?: Variant;
  productCodeIdentifier?: string;
  productName?: string;
  variantName?: string;
  colorName?: string;
  color?: Color;
  colorId?: number;
  sizeName?: string;
  size?: Size;
  sizeId?: number;
  amount?: number;
  unitPrice?: number;
  totalPrice?: number;
  taxType?: string;
  returned?: boolean;
  returnedAmount?: number;
  receivedReturnedAmount?: number;
  returnedPrice?: number;
  cashRegisterOperationId?: number;
  hasCustomPrice?: boolean;
  variantAvailableAmount?: number;
  totalCodeAmount?: number;
  normalPrice?: number;
  wholesalePrice?: number;
  superWholesalePrice?: number;
  wholesaleThreshold?: number;
  superWholesaleThreshold?: number;
  barcode?: string;
  discountPercentage?: number;
  discountType?: string;
  listPrice?: number;
  categoryId?: number;
}

interface StoreSale {
  id?: number;
  companyId?: number;
  storeId?: number;
  storeName?: string;
  sellerId?: number;
  sellerName?: string;
  clientId?: number;
  client?: Client;
  clientName?: string;
  clientDocument?: string;
  saleDate?: string;
  status?: string;
  paymentType?: string;
  paymentTypeAmount?: number;
  paymentType2?: string;
  paymentType2Amount?: number;
  totalPrice?: number;
  discountedPrice?: number;
  returnedPrice?: number;
  discount?: number;
  discountType?: string;
  saleProfit?: number;
  saleProductCosts?: number;
  obs?: string;
  pdfUrl?: string;
  isReturnOperation?: boolean;
  hasReturnedItems?: boolean;
  cashRegisterOperationId?: number;
  returnOperationSeller?: string;
  returnOperationSellerId?: number;
  returnOperationStoreId?: number;
  returnOperationStoreName?: string;
  returnOperationDate?: string;
  returnOperationCashRegisterOperationId?: number;

  cdcInvoice?: string;
  clientLegalName?: string;
  clientRuc?: string;
  stamp?: string;
  stampBeginName?: string;
  dispatchPoint?: string;
  establishment?: string;
  documentNumber?: string;
  saleType?: string;
  invoiceStatus?: string;

  items?: StoreSaleItem[];

  iva?: number;
  taxed?: number;
  noTaxed?: number;
  credit?: number;
  cancelled?: boolean;
  cancelationReturnToInventory?: boolean;
  totalDiscount?: number;
  totalJeans?: number;
  totalDonna?: number;
  totalOthers?: number;
}

export const newStoreSale = (): StoreSale => {
  return {};
};

type OrderMaps = { [key: string]: string };

export const STORESALETYPES: OrderMaps = {
  saleOperation: "VENTA",
  returnOperation: "DEVOLUCION",
};

export const getSaleOperationType = (key: string) => {
  return STORESALETYPES[key];
};

export const getStoreSaleOperationType = (storeSale: StoreSale): string => {
  if (
    storeSale.isReturnOperation !== undefined &&
    storeSale.isReturnOperation === true
  ) {
    return "Devolución";
  }
  return "Venta";
};

export const getStoreSaleItemTaxType = (item: StoreSaleItem): string => {
  if (item.taxType === undefined) {
    return "Gravada 10%";
  }
  if (item.taxType === "iva_10") {
    return "Gravada 10%";
  }
  if (item.taxType === "iva_5") {
    return "Gravada 5%";
  }
  return "Exenta";
};

export const calculateItemIVA = (item: StoreSaleItem): number => {
  let taxFactor;
  if (item.taxType === undefined) {
    taxFactor = 11;
  } else if (item.taxType === "iva_10") {
    taxFactor = 11;
  } else if (item.taxType === "iva_5") {
    taxFactor = 21;
  } else {
    return 0;
  }

  if (item.totalPrice === undefined) {
    return 0;
  }
  return item.totalPrice / taxFactor;
};

export default StoreSale;
