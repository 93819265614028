import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import Datetime from "react-datetime";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getList,
  getItem,
} from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import SaleOrder, { SaleOrderItem } from "../../models/sale-order";
import ClientSelect from "../client/ClientSelect";
import Client from "../../models/client";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import {
  UniqueListItem,
  listToUniqueListItems,
  uniqueListToListItems,
} from "../../models/unique-list-item";
import DeliveryOrderItemForm from "./DeliveryOrderItemForm";
import SaleOrderSelect from "../sale-orders/SaleOrderSelect";
import DeliveryOrder, {
  DeliveryOrderItem,
  newDeliveryOrder,
} from "../../models/delivery-order";
import InventoryLocationSelect from "../inventory-location/InventoryLocationSelect";
import InventoryLocation, {
  INVENTORY_LOCATION_TYPE_PRODUCTS,
} from "../../models/inventory-location";
import {
  DeliveryOrderFormItem,
  deliveryOrderAPIReceivedToTableFormat,
  deliveryOrderTableFormatToAPI,
  saleOrderAPIReceivedToDeliveryTableFormat,
} from "../../utils/delivery-orders";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";
import { BatchItemSellLogs } from "../../models/inventory-input-batch";
import Variant from "../../models/variant";
import Product from "../../models/product";
import DeliveryOrderDiscountItemForm from "./DeliveryOrderDiscountItemForm";
import { standardSizes } from "../../utils/constants/sizes";

interface DeliveryOrderFormProps {
  initialDeliveryOrder?: DeliveryOrder;
  initialErrors?: Errors;
  disabled?: boolean;
  onCancel?: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = [
  "Prenda",
  "Color",
  "Precio Unit.",
  "Subtotal",
  "Cant. Total",
];

const DeliveryOrderForm: React.FC<DeliveryOrderFormProps> = ({
  initialDeliveryOrder,
  initialErrors,
  disabled,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const [editingDeliveryOrder, setEditingDeliveryOrder] =
    useState<DeliveryOrder>(
      initialDeliveryOrder ? initialDeliveryOrder : newDeliveryOrder()
    );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [orderDate, setOrderDate] = useState<string | moment.Moment>("");
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [estimatedDate, setEstimatedDate] = useState<string | moment.Moment>(
    ""
  );

  const [productsAdded, setProductsAdded] = useState<Product[]>([]);

  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [deliveryOrderItems, setDeliveryOrderItems] = useState<
    UniqueListItem<DeliveryOrderFormItem>[]
  >([]);
  const [barcodeTobeAdded, setBarcodeToBeAdded] = useState<string>("");
  const [batchLogs, setBatchLogs] = useState<BatchItemSellLogs[]>([]);
  const barcodeInputRef = useRef<HTMLInputElement>(null);
  const [enableDiscountPerUnit, setEnableDiscountPerUnit] = useState(false);

  const onEstimatedDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }
    setEstimatedDate(value);
    if (moment.isMoment(value)) {
      setEditingDeliveryOrder({
        ...editingDeliveryOrder,
        orderEstimatedPayment: value.toISOString(),
      });
    } else if (value !== undefined && value !== "") {
      setEditingDeliveryOrder({
        ...editingDeliveryOrder,
        orderEstimatedPayment: value,
      });
    }
  };

  const onEnableDiscountChange = (enablediscount: boolean) => {
    let newProductsAdded = productsAdded.map((product) => ({
      ...product,
      price: 0,
      wholesalePrice: (product.wholesalePrice || 0) + 1,
    }));
    setProductsAdded(newProductsAdded);
    setEnableDiscountPerUnit(enablediscount);
    setEditingDeliveryOrder({
      ...editingDeliveryOrder,
      hasDiscount: enablediscount,
      discountPerUnit: 0,
      totalPrice: enablediscount
        ? editingDeliveryOrder.totalPrice
        : editingDeliveryOrder.totalPriceNoDiscount,
      totalPriceNoDiscount: enablediscount
        ? editingDeliveryOrder.totalPrice
        : editingDeliveryOrder.totalPriceNoDiscount,
    });
  };

  const onDiscountPerProductUnitChange = useCallback(
    async (product: Product) => {
      let newProductsAdded = [...productsAdded];
      const index = newProductsAdded.findIndex((item) => {
        return item.id === product.id;
      });

      if (index !== -1) {
        newProductsAdded[index] = product;
      }
      const newDiscount = calculateProductDiscountPrice(
        deliveryOrderItems,
        newProductsAdded
      );

      setProductsAdded(newProductsAdded);
      setEditingDeliveryOrder({
        ...editingDeliveryOrder,
        totalPrice:
          (editingDeliveryOrder.totalPriceNoDiscount
            ? editingDeliveryOrder.totalPriceNoDiscount
            : editingDeliveryOrder.totalPrice!) - newDiscount,
      });
    },
    [productsAdded, editingDeliveryOrder, deliveryOrderItems]
  );

  const calculateProductDiscountPrice = (
    items: UniqueListItem<DeliveryOrderFormItem>[],
    productsAddedValues: Product[]
  ): number => {
    return items.reduce((total, item) => {
      const itemTotal =
        item.item.sizes?.reduce((subtotal, size) => {
          return subtotal + (size.amountDelivered || 0);
        }, 0) || 0;

      const matchingProduct = productsAddedValues.find(
        (product) => product.id === item.item.clothingProductId
      );
      const discountPerUnit = matchingProduct ? matchingProduct.price || 0 : 0;

      return total + itemTotal * discountPerUnit;
    }, 0);
  };

  const onOrderDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }
    setOrderDate(value);
    if (moment.isMoment(value)) {
      setEditingDeliveryOrder({
        ...editingDeliveryOrder,
        orderDate: value.toISOString(),
      });
    } else if (value !== undefined && value !== "") {
      setEditingDeliveryOrder({
        ...editingDeliveryOrder,
        orderDate: value,
      });
    }
  };

  const onClientChange = (newClient: Client | null) => {
    setEditingDeliveryOrder((editingItem) => {
      return {
        ...editingItem,
        clientId: newClient !== null ? newClient.id : undefined,
        clientName: newClient !== null ? newClient.name : undefined,
        client: newClient !== null ? newClient : undefined,
        saleOrderId: undefined,
        saleOrder: undefined,
        totalPrice: undefined,
        totalPriceNoDiscount: undefined,
      };
    });
    setProductsAdded([]);
    setDeliveryOrderItems([]);
    setTotalQuantity(0);
  };

  const onSaleOrderChange = (modifiedSaleOrder: SaleOrder | null) => {
    setEditingDeliveryOrder((editingItem) => {
      return {
        ...editingItem,
        saleOrderId:
          modifiedSaleOrder !== null ? modifiedSaleOrder.id : undefined,
        saleOrder: modifiedSaleOrder !== null ? modifiedSaleOrder : undefined,
        totalPrice: undefined,
        totalPriceNoDiscount: undefined,
      };
    });
    fetchDeliveryOrderItems(
      modifiedSaleOrder !== null ? modifiedSaleOrder.id : undefined
    );
  };

  const onInventoryLocationChange = (
    newInventoryLocation: InventoryLocation | null
  ) => {
    setEditingDeliveryOrder((editingItem) => {
      return {
        ...editingItem,
        inventoryLocationId:
          newInventoryLocation !== null ? newInventoryLocation.id : undefined,
        inventoryLocation:
          newInventoryLocation !== null ? newInventoryLocation : undefined,
        inventoryLocationName:
          newInventoryLocation !== null
            ? newInventoryLocation.identifier
            : undefined,
      };
    });
  };

  const onBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarcodeToBeAdded(e.target.value);
  };

  const onBarcodeSend = async () => {
    if (barcodeTobeAdded === "") {
      errorAlert("Debe Completar el campo codigo de barras");
      return;
    }
    await fetchVariantFromBarcode(barcodeTobeAdded);
  };

  const onBarcodeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && barcodeTobeAdded !== "") {
      onBarcodeSend();
    }
  };

  const fetchVariantFromBarcode = async (barcode: string) => {
    barcode = barcode.trim().replace(/[\/\s]/g, "");
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    const variantStatus = await getItem<Variant>(
      `/product_variants/barcode/${barcode}/`,
      additionalParams
    );
    if (variantStatus.status === SUCCESS) {
      if (variantStatus.data === undefined) {
        errorAlert("Ocurrió un error");
        setBarcodeToBeAdded("");
        return;
      }
      processVariantToTable(variantStatus.data, barcode);
    } else {
      const message = variantStatus.detail
        ? variantStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }

    setBarcodeToBeAdded("");
    if (barcodeInputRef.current !== null) {
      barcodeInputRef.current.focus();
    }
  };

  const processVariantToTable = (variant: Variant, barcode: string) => {
    if (!variant.id) {
      errorAlert("Variante no tiene un ID válido");
      return;
    }

    const newDeliveryItems = [...deliveryOrderItems];
    const itemIndex = newDeliveryItems.findIndex((item) =>
      item.item.sizes?.some((size) => size.variantId === variant.id)
    );

    if (itemIndex === -1) {
      errorAlert("Variante no está entre las opciones disponibles");
      return;
    }

    const affectedItem = newDeliveryItems[itemIndex];
    const sizeIndex = affectedItem.item.sizes?.findIndex(
      (size) => size.variantId === variant.id
    );

    if (sizeIndex === undefined || sizeIndex === -1) {
      errorAlert("El tamaño asociado a la variante no está disponible");
      return;
    }
    if (!affectedItem.item.sizes) {
      errorAlert("Ocurrió un error");
      return;
    }
    const affectedSize = affectedItem.item.sizes[sizeIndex];

    if (
      affectedSize.maxAmountAllowed !== undefined &&
      (affectedSize.amountDelivered || 0) + 1 > affectedSize.maxAmountAllowed
    ) {
      errorAlert("La cantidad entregada excede la cantidad máxima permitida");
      return;
    }

    const newSizeItems = [...affectedItem.item.sizes];
    newSizeItems[sizeIndex] = {
      ...affectedSize,
      amountDelivered: (affectedSize.amountDelivered || 0) + 1,
    };

    const newTotalRequiredClothesAmount = newSizeItems.reduce(
      (total, item) => total + (item.amountDelivered || 0),
      0
    );

    const currentPriceToMultiply = affectedItem.item.unitPriceNoDiscount || 0;

    const newEditingItem: UniqueListItem<DeliveryOrderFormItem> = {
      ...affectedItem,
      item: {
        ...affectedItem.item,
        totalPriceNoDiscount:
          newTotalRequiredClothesAmount * currentPriceToMultiply,
        sizes: newSizeItems,
      },
    };

    // Update the deliveryOrderItems with the modified item
    newDeliveryItems[itemIndex] = newEditingItem;
    let calculatedTotalQuantity = calculateTotalQuantity(newDeliveryItems);
    setTotalQuantity(calculatedTotalQuantity);
    setDeliveryOrderItems(newDeliveryItems);

    // Calculate the new total and discount
    const newTotal = newDeliveryItems.reduce(
      (total, formItem) => total + (formItem.item.totalPriceNoDiscount || 0),
      0
    );

    const newDiscount = calculateProductDiscountPrice(
      newDeliveryItems,
      productsAdded
    );

    setEditingDeliveryOrder({
      ...editingDeliveryOrder,
      totalPrice: newTotal - newDiscount,
      totalPriceNoDiscount: newTotal,
    });
    processBatchLogs(variant, 1, barcode);
  };

  const processBatchLogs = (
    variant: Variant,
    amount: number,
    barcode: string
  ) => {
    if (!variant.productionOrderItemId) {
      return;
    }
    setBatchLogs((prevLogs) => {
      const existingLogIndex = prevLogs.findIndex(
        (log) => log.barcode === barcode
      );

      if (existingLogIndex !== -1) {
        const updatedLogs = [...prevLogs];
        updatedLogs[existingLogIndex].amount =
          (updatedLogs[existingLogIndex].amount || 0) + amount;
        return updatedLogs;
      } else {
        const newLog: BatchItemSellLogs = {
          id: undefined,
          companyId: variant.companyId,
          logDate: undefined,
          batchId: variant.batchId,
          productionOrderItemId: variant.productionOrderItemId,
          productionOrderId: variant.productionOrderId,
          storeSaleId: undefined,
          variantId: variant.id,
          productCodeIdentifier: variant.productCodeIdentifier,
          productName: variant.productName,
          variantName: variant.name,
          colorName: variant.color,
          sizeName: variant.size,
          barcode: barcode,
          amount: amount,
          registerType: "automatic",
          obs: "Orden de Envío de Fabrica",
        };

        return [...prevLogs, newLog];
      }
    });
  };

  const getPaymentStatus = (
    total: number,
    paid: number | undefined
  ): string => {
    if (paid === undefined || paid === 0) {
      return "pending";
    }
    const difference = total - paid;
    if (difference === 0 || difference < 0) {
      return "paid";
    }
    if (difference > 0) {
      return "partial";
    }
    return "pending";
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingDeliveryOrder({
      ...editingDeliveryOrder,
      obs: e.target.value,
    });
  };

  /*const onAmountToPayChange = (newUnitPrice: number | undefined) => {
    setEditingDeliveryOrder({
      ...editingDeliveryOrder,
      paidPrice: newUnitPrice,
    });
  };*/

  const calculateTotalQuantity = (
    tableItems: UniqueListItem<DeliveryOrderFormItem>[]
  ): number => {
    return tableItems.reduce(
      (total, entry) =>
        total +
        (entry.item.sizes?.reduce(
          (total, entry) => total + (entry.amountDelivered || 0),
          0
        ) || 0),
      0
    );
  };

  const ondeliveryOrderFormItemEntryChange = useCallback(
    (deliveryOrderFormItem: UniqueListItem<DeliveryOrderFormItem>) => {
      const newDeliveryOrderItems = [...deliveryOrderItems];
      const index = newDeliveryOrderItems.findIndex((item) => {
        return item.uuid === deliveryOrderFormItem.uuid;
      });

      if (index === -1) {
        setDeliveryOrderItems(newDeliveryOrderItems);
        return;
      }

      if (
        deliveryOrderFormItem.item.colorId !==
        newDeliveryOrderItems[index].item.colorId
      ) {
        const sameProductAndColorIndex = newDeliveryOrderItems.findIndex(
          (item) => {
            return (
              item.item.clothingProductId ===
                deliveryOrderFormItem.item.clothingProductId &&
              item.item.colorId === deliveryOrderFormItem.item.colorId
            );
          }
        );
        if (sameProductAndColorIndex !== -1) {
          warningAlert(
            "Este producto en este color ya fue agregado previamente a la lista"
          );
        }
      }

      newDeliveryOrderItems[index] = deliveryOrderFormItem;

      let calculatedTotalQuantity = calculateTotalQuantity(
        newDeliveryOrderItems
      );
      setTotalQuantity(calculatedTotalQuantity);

      setDeliveryOrderItems(newDeliveryOrderItems);

      const updatedBatchLogs = [...batchLogs];
      deliveryOrderFormItem.item?.sizes?.forEach((sizeItem) => {
        const matchingLog = updatedBatchLogs.find(
          (log) => log.variantId === sizeItem.variantId
        );

        if (
          matchingLog &&
          (sizeItem.amountDelivered || 0) < (matchingLog.amount || 0)
        ) {
          matchingLog.amount = sizeItem.amountDelivered;
        }
      });

      setBatchLogs(updatedBatchLogs);

      const newTotal = newDeliveryOrderItems.reduce(
        (total, formItem) => total + (formItem.item.totalPriceNoDiscount || 0),
        0
      );

      const newDiscount = calculateProductDiscountPrice(
        deliveryOrderItems,
        productsAdded
      );
      setEditingDeliveryOrder({
        ...editingDeliveryOrder,
        totalPrice: newTotal - newDiscount,
        totalPriceNoDiscount: newTotal,
      });
    },
    [deliveryOrderItems, editingDeliveryOrder, batchLogs]
  );

  const generateTableHeaders = (
    orderItems: UniqueListItem<DeliveryOrderFormItem>[]
  ) => {
    const uniqueSizeNames: string[] = [];

    orderItems.forEach((item) => {
      if (item.item.sizes) {
        item.item.sizes.forEach((size) => {
          const sizeName = size?.sizeName;
          if (sizeName && !uniqueSizeNames.includes(sizeName)) {
            uniqueSizeNames.push(sizeName);
          }
        });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      // Check if both sizes are in the standard sizes list
      const aIndex = standardSizes.indexOf(a);
      const bIndex = standardSizes.indexOf(b);

      // If both sizes are found in the standard sizes list, sort by their order in the list
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }

      // If only one of the sizes is in the standard sizes list, prioritize the one that is
      if (aIndex !== -1) return -1; // a comes first
      if (bIndex !== -1) return 1; // b comes first

      // Otherwise, sort them by string length and numeric value as before
      const keyLengthDiff = a.length - b.length;
      if (keyLengthDiff !== 0) {
        return keyLengthDiff;
      }

      return parseInt(a) - parseInt(b);
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames]);
  };

  const generateProductsAdded = (
    orderItems: UniqueListItem<DeliveryOrderFormItem>[]
  ) => {
    const products: Product[] = [];

    orderItems.forEach((item) => {
      if (item.item.clothingProduct) {
        const newProduct = item.item.clothingProduct;
        const discount =
          (item.item.unitPriceNoDiscount || 0) - (item.item.unitPrice || 0);
        newProduct.price = discount;
        if (!products.some((product) => product.id === newProduct.id)) {
          products.push(newProduct);
        }
      }
    });

    products.sort((a, b) => {
      const aStr = a.codeIdentifier || "";
      const bStr = b.codeIdentifier || "";
      return aStr.localeCompare(bStr);
    });

    setProductsAdded(products);
  };

  const fetchDeliveryOrderItems = async (
    newSaleOrderId: number | undefined
  ) => {
    if (
      initialDeliveryOrder !== undefined &&
      initialDeliveryOrder.id !== undefined
    ) {
      const deliveryOrderIdStr = initialDeliveryOrder.id.toString();
      if (deliveryOrderIdStr === undefined) {
        setDeliveryOrderItems([]);

        setTotalQuantity(0);
        return;
      }
      const limit = 5000;
      const offset = 0;
      let additionalParams: Map<string, string> | undefined = new Map<
        string,
        string
      >();

      additionalParams.set("company_id", company.id!.toString());
      additionalParams.set("delivery_order_id", deliveryOrderIdStr);
      const itemsStatus = await getList<DeliveryOrderItem>(
        `/delivery_orders/items/`,
        limit,
        offset,
        additionalParams
      );
      if (itemsStatus.status === SUCCESS) {
        if (itemsStatus.data !== undefined) {
          const items = listToUniqueListItems(
            deliveryOrderAPIReceivedToTableFormat(itemsStatus.data.items)
          );
          let calculatedTotalQuantity = calculateTotalQuantity(items);
          setTotalQuantity(calculatedTotalQuantity);
          setDeliveryOrderItems(items);
          generateTableHeaders(items);
          generateProductsAdded(items);
        }
      } else {
        setDeliveryOrderItems([]);
        setTotalQuantity(0);
      }
    } else {
      if (newSaleOrderId === undefined) {
        setDeliveryOrderItems([]);
        setTotalQuantity(0);
        return;
      }
      const saleOrderIdStr = newSaleOrderId.toString();
      if (saleOrderIdStr === undefined) {
        setDeliveryOrderItems([]);
        setTotalQuantity(0);
        return;
      }
      const limit = 5000;
      const offset = 0;
      let additionalParams: Map<string, string> | undefined = new Map<
        string,
        string
      >();

      additionalParams.set("company_id", company.id!.toString());
      additionalParams.set("sale_order_id", saleOrderIdStr);
      const itemsStatus = await getList<SaleOrderItem>(
        `/sale_orders/items/`,
        limit,
        offset,
        additionalParams
      );
      if (itemsStatus.status === SUCCESS) {
        if (itemsStatus.data !== undefined) {
          const items = listToUniqueListItems(
            saleOrderAPIReceivedToDeliveryTableFormat(itemsStatus.data.items)
          );
          setDeliveryOrderItems(items);
          let calculatedTotalQuantity = calculateTotalQuantity(items);
          setTotalQuantity(calculatedTotalQuantity);
          generateTableHeaders(items);
          generateProductsAdded(items);
        }
      } else {
        setDeliveryOrderItems([]);
      }
    }
  };

  const calculateCost = (items: DeliveryOrderItem[]): number => {
    const total = items.reduce((acc, item) => {
      const price =
        (item.clothingProduct?.productionCost ?? 0) *
        (item.amountDelivered || 0);
      return acc + price;
    }, 0);

    return total;
  };

  const onSave = async () => {
    setSubmitting(true);
    const total = editingDeliveryOrder.totalPrice;
    if (total === undefined || total === 0) {
      warningAlert("La orden de envío no puede tener Monto total 0");
      setSubmitting(false);
      return;
    }
    const paid = editingDeliveryOrder.paidPrice;
    const toSendItems: DeliveryOrderItem[] = deliveryOrderTableFormatToAPI(
      uniqueListToListItems(deliveryOrderItems)
    );

    toSendItems.forEach((item) => {
      let matchingProductForItem = productsAdded.find(
        (product) => product.id === item.clothingProductId
      );
      if (editingDeliveryOrder.id == undefined) {
        if (item.unitPrice && item.totalPrice) {
          item.unitPrice =
            item.unitPriceNoDiscount! - (matchingProductForItem?.price || 0);
          item.totalPrice = item.unitPrice * (item.amountDelivered ?? 0);
        }
      } else {
        if (item.unitPriceNoDiscount && item.totalPriceNoDiscount) {
          const expectedUnitPrice =
            item.unitPriceNoDiscount - (matchingProductForItem?.price || 0);

          if (item.unitPrice !== expectedUnitPrice) {
            item.unitPrice = expectedUnitPrice;
            item.totalPrice = item.unitPrice * (item.amountDelivered ?? 0);
          }
        }
      }
    });

    const cost = calculateCost(toSendItems);
    const profit = (editingDeliveryOrder.totalPrice ?? 0) - cost;

    const paymentStatus = getPaymentStatus(total, paid);

    let toSendLogs: BatchItemSellLogs[] = batchLogs.map((log) => ({
      ...log,
      logDate: editingDeliveryOrder.orderDate,
    }));

    let toSendDeliveryOrder: DeliveryOrder = {
      ...editingDeliveryOrder,
      companyId: company.id,
      paymentStatus: paymentStatus,
      deliveryOrderItems: toSendItems,
      paidPrice: paid ? paid : 0,
      saleProductCosts: cost,
      saleProfit: profit,
      batchLogs: toSendLogs,
    };

    let requestPromise: Promise<ItemRequestStatus<DeliveryOrder>>;
    if (editingDeliveryOrder.id === undefined) {
      requestPromise = createItem<DeliveryOrder>(
        "/delivery_orders/create/",
        toSendDeliveryOrder
      );
    } else {
      requestPromise = updateItem<DeliveryOrder>(
        `/delivery_orders/${toSendDeliveryOrder.id}/`,
        toSendDeliveryOrder
      );
    }

    const saleOrderStatus = await requestPromise;

    if (saleOrderStatus.status !== SUCCESS) {
      if (saleOrderStatus.errors !== undefined) {
        setErrors(saleOrderStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (saleOrderStatus.detail !== undefined) {
        message = saleOrderStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const clearForm = () => {
    setEditingDeliveryOrder(newDeliveryOrder());
    setDeliveryOrderItems([]);
    setTotalQuantity(0);
    setOrderDate("");
    setEstimatedDate("");
    setEnableDiscountPerUnit(false);
    generateTableHeaders([]);
    setBarcodeToBeAdded("");
    setBatchLogs([]);
    setProductsAdded([]);
  };

  useEffect(() => {
    setEditingDeliveryOrder(
      initialDeliveryOrder ? initialDeliveryOrder : newDeliveryOrder()
    );
    const date = initialDeliveryOrder?.orderDate;
    setOrderDate(date ? moment(date) : "");
    const estimatedDate = initialDeliveryOrder?.orderEstimatedPayment;
    setEstimatedDate(estimatedDate ? moment(estimatedDate) : "");
    if (initialDeliveryOrder?.hasDiscount) {
      setEnableDiscountPerUnit(true);
    }
    setBatchLogs([]);
    fetchDeliveryOrderItems(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDeliveryOrder]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);
  return (
    <>
      <fieldset>
        <CFormGroup>
          <CRow className={"mt-2"}>
            <CCol md={2}>
              <CLabel className={"mt-2"}>Fecha:</CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onOrderDatehange}
                value={orderDate}
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={getFieldErrors("orderDate", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Cliente:</CLabel>
            </CCol>
            <CCol>
              <ClientSelect
                value={
                  editingDeliveryOrder.client !== undefined
                    ? editingDeliveryOrder.client
                    : null
                }
                onChange={onClientChange}
                disabled={disabled}
              ></ClientSelect>
              <FieldErrors
                errors={getFieldErrors("clientId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Orden de venta:</CLabel>
            </CCol>
            <CCol md={4}>
              <SaleOrderSelect
                value={
                  editingDeliveryOrder.saleOrder !== undefined
                    ? editingDeliveryOrder.saleOrder
                    : null
                }
                clientId={editingDeliveryOrder.clientId}
                key={
                  editingDeliveryOrder.clientId
                    ? editingDeliveryOrder.clientId
                    : 0
                }
                onChange={onSaleOrderChange}
                disabled={disabled}
                onlyDeliveryPending={true}
              ></SaleOrderSelect>
            </CCol>
            <CCol md={2}>
              <CLabel>Inventario:</CLabel>
            </CCol>
            <CCol md={4}>
              <InventoryLocationSelect
                value={
                  editingDeliveryOrder.inventoryLocation !== undefined
                    ? editingDeliveryOrder.inventoryLocation
                    : null
                }
                onChange={onInventoryLocationChange}
                inventoryType={INVENTORY_LOCATION_TYPE_PRODUCTS}
                locationType={"factory"}
                disabled={disabled}
              ></InventoryLocationSelect>
              <FieldErrors
                errors={
                  getFieldErrors("inventoryLocationId", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Monto total:</CLabel>
            </CCol>
            <CCol md={4}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingDeliveryOrder.totalPrice !== undefined
                    ? editingDeliveryOrder.totalPrice
                    : 0
                }
              ></CurrencyField>
            </CCol>
            <CCol md={2} hidden={!enableDiscountPerUnit}>
              <CLabel>Monto total Sin descuento:</CLabel>
            </CCol>
            <CCol md={4} hidden={!enableDiscountPerUnit}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingDeliveryOrder.totalPriceNoDiscount !== undefined
                    ? editingDeliveryOrder.totalPriceNoDiscount
                    : 0
                }
              ></CurrencyField>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingDeliveryOrder.obs)}
                onChange={onObsChange}
                placeholder="Observación"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow className={"mt-2"}>
            <CCol md={2}>
              <CLabel className={"mt-2"}>
                Fecha estimada de pago (Opcional):
              </CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onEstimatedDatehange}
                value={estimatedDate}
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={
                  getFieldErrors("orderEstimatedPayment", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Aplicar descuento por prenda(pago contado):</CLabel>
            </CCol>
            <CCol md={4}>
              <CRow>
                <AnimatedCheckbox
                  value={enableDiscountPerUnit}
                  onChange={onEnableDiscountChange}
                ></AnimatedCheckbox>
              </CRow>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow hidden={disabled}>
            <CCol md={2} lg={2} xl={1} className={"mt-2"}>
              <CLabel>Cód. de barras:</CLabel>
            </CCol>
            <CCol md={3} lg={2}>
              <CInput
                innerRef={barcodeInputRef}
                type="text"
                value={barcodeTobeAdded}
                onChange={onBarcodeChange}
                onKeyDown={onBarcodeKeyDown}
                placeholder="Código de barras"
              ></CInput>
            </CCol>
            <CCol md={1}>
              <CButton
                className="btn btn-primary"
                onClick={onBarcodeSend}
                disabled={disabled}
              >
                Ingresar
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
      </fieldset>
      <CTabs activeTab="products">
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink data-tab="products">Productos</CNavLink>
          </CNavItem>
          {enableDiscountPerUnit ? (
            <CNavItem>
              <CNavLink data-tab="discounts">Descuentos</CNavLink>
            </CNavItem>
          ) : (
            <></>
          )}
        </CNav>
        <CTabContent>
          <CTabPane data-tab="products">
            <fieldset
              disabled={editingDeliveryOrder.paymentStatus === "reverted"}
            >
              <CFormGroup>
                <CRow>
                  <CCol md={12}>
                    <h5 className="float-right">{`Total de artículos: ${totalQuantity}`}</h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={12}>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "delivery_order_items",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={12}>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-fixed">
                        <thead>
                          <tr>
                            {tableColumns.map((title, ix) => {
                              return (
                                <th
                                  className="text-center"
                                  key={ix}
                                  style={{
                                    verticalAlign: "middle",
                                    overflow: "hidden",
                                  }}
                                >
                                  <div className="d-inline">{title}</div>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {deliveryOrderItems.map(
                            (deliveryOrderFormItem, ix) => {
                              return (
                                <DeliveryOrderItemForm
                                  key={deliveryOrderFormItem.uuid}
                                  value={deliveryOrderFormItem.item}
                                  sizesColumns={tableColumns}
                                  initialValue={deliveryOrderFormItem.item}
                                  onChange={(item) =>
                                    ondeliveryOrderFormItemEntryChange({
                                      uuid: deliveryOrderFormItem.uuid,
                                      item: item,
                                    })
                                  }
                                  errors={{}}
                                />
                              );
                            }
                          )}
                          {deliveryOrderItems.length === 0 ? (
                            <tr>
                              <td colSpan={tableColumns.length}>
                                No hay items disponibles para esta orden de
                                venta
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <br />
                      <br />
                    </div>
                  </CCol>
                </CRow>
              </CFormGroup>
              <CFormGroup className="float-right">
                <CButtonGroup>
                  <CButton type="submit" color="primary" onClick={onSave}>
                    {submitting ? (
                      <Spinner
                        animation="grow"
                        style={{
                          height: "17px",
                          width: "17px",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {submitting ? "Guardando..." : "Guardar"}
                  </CButton>
                </CButtonGroup>
              </CFormGroup>
            </fieldset>
          </CTabPane>
          <CTabPane data-tab="discounts">
            {productsAdded.length ? (
              productsAdded.map((product, ix) => {
                return (
                  <CRow key={`${product.id}-${product.wholesalePrice}`}>
                    <CCol>
                      <DeliveryOrderDiscountItemForm
                        value={product}
                        initialPrice={product.price}
                        onChange={(item) =>
                          onDiscountPerProductUnitChange(item)
                        }
                        disabled={!enableDiscountPerUnit}
                      />
                    </CCol>
                  </CRow>
                );
              })
            ) : (
              <p className="mt-2">
                No hay items disponibles para esta orden de venta
              </p>
            )}
          </CTabPane>
        </CTabContent>
      </CTabs>
    </>
  );
};

export default DeliveryOrderForm;
