import { PYG } from "./available-currencies";

export const formatToPyg = (value: number): string => {
  return `₲${value.toLocaleString()}`;
};

export const formatToUsd = (value: number): string => {
  return `$${value.toLocaleString()}`;
};

export const formatToCurrency = (value: number, currency: string): string => {
  if (currency === PYG) {
    return formatToPyg(value);
  }
  return formatToUsd(value);
};

export const formatToPygRounded = (value: number): string => {
  return `₲${Math.round(value).toLocaleString()}`;
};

export const formatToUsdRounded = (value: number): string => {
  return `$${Math.round(value).toLocaleString()}`;
};

export const formatToCurrencyRounded = (
  value: number,
  currency: string
): string => {
  if (currency === PYG) {
    return formatToPygRounded(value);
  }
  return formatToUsdRounded(value);
};
