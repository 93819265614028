import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CLink,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import Store from "../../models/store";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import { PYG } from "../../currency/available-currencies";
import { formatToCurrency } from "../../currency/format";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  INVENTORY_PRODUCTS_RETRIEVE,
  INVENTORY_VALUES_USE,
  STORE_SALES_RETRIEVE,
} from "../../auth/permissions";
import RecalculateModal from "./RecalculateModal";
import StoreUpdateModal from "./StoreUpdateModal";

const ITEMS_PER_PAGE = 10;

const StoreList = () => {
  const fields = [
    {
      key: "name",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "inventoryValue",
      _classes: ["text-center"],
      label: "Regstro Valor de Mercaderia Enviado/Deuda",
    },
    {
      key: "storeRequestsCredit",
      _classes: ["text-center"],
      label: "Crédito para pedidos",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState<Store[]>([]);
  const [selectedStore, setSelectedStore] = useState<Store | undefined>(
    undefined
  );
  const [totalPages, setTotalPages] = useState(1);
  const [showRecalculateModal, setShowRecalculateModal] = useState(false);
  const [showStoreEditModal, setShowStoreEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchStores = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const storesStatus = await getList<Store>(
      "/stores/",
      limit,
      offset,
      urlParams
    );
    if (storesStatus.status === SUCCESS) {
      if (storesStatus.data !== undefined) {
        const count = storesStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setStores(storesStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = storesStatus.detail
        ? storesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onRecalculateClick = () => {
    setShowRecalculateModal(true);
  };

  const onRecalculateCancel = () => {
    setShowRecalculateModal(false);
  };

  const onRecalculateSuccess = () => {
    successAlert("Valores de Inventarios recalculados con éxito!");
    setShowRecalculateModal(false);
    setStores([]);
    setLoading(true);
    fetchStores();
  };

  const onEditClick = (newStore: Store) => {
    setSelectedStore(newStore);
    setShowStoreEditModal(true);
  };

  const onEditCancel = () => {
    setSelectedStore(undefined);
    setShowStoreEditModal(false);
  };

  const onEditSuccess = () => {
    successAlert("Valores actualizado con éxito!");
    setShowStoreEditModal(false);
    setSelectedStore(undefined);
    setStores([]);
    setLoading(true);
    fetchStores();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("stores")) {
        return;
      }
      fetchStores();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Tiendas</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={INVENTORY_VALUES_USE}
                >
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onRecalculateClick}
                  >
                    &nbsp; Recalcular valores de inventarios
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={stores}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    inventoryValue: (item: Store) => {
                      return (
                        <td className="text-center">
                          {item.inventoryValue
                            ? formatToCurrency(item.inventoryValue, PYG)
                            : "-"}
                        </td>
                      );
                    },
                    storeRequestsCredit: (item: Store) => {
                      let valueToShow = item.storeRequestsCredit
                        ? formatToCurrency(item.storeRequestsCredit, PYG)
                        : "-";
                      if (
                        item.name === "Fábrica" ||
                        item.name === "Prendas Devueltas"
                      ) {
                        valueToShow = "-";
                      }
                      return <td className="text-center">{valueToShow}</td>;
                    },
                    actions: (item: Store) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={STORE_SALES_RETRIEVE}
                        >
                          <CTooltip
                            content={
                              "Modificar porcentaje de ventas para crédito de pedidos"
                            }
                          >
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onEditClick(item);
                              }}
                            >
                              <i className="fa fa-percentage"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const checkInventoryButton = (
                        <PermissionRequiredComponent
                          permissionName={INVENTORY_PRODUCTS_RETRIEVE}
                        >
                          <CTooltip content={"Ver Inventario de Sucursal"}>
                            <CLink
                              to={
                                item.name === "Fábrica"
                                  ? getRoutePath("inventoryProducts")
                                  : `/stores/${item.id}/inventory/`
                              }
                            >
                              <CButton className="text-white" color="primary">
                                <i className="fa fa-shirt"></i>
                              </CButton>
                            </CLink>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const checkDebtHistoryButton =
                        item.name !== "Fábrica" &&
                        item.name !== "Prendas Devueltas" ? (
                          <CTooltip
                            content={"Ver Registro de Deuda de Sucursal"}
                          >
                            <CLink to={`/stores/${item.id}/debt_log/`}>
                              <CButton className="text-white" color="success">
                                <i className="fa fa-money-bill"></i>
                              </CButton>
                            </CLink>
                          </CTooltip>
                        ) : (
                          <></>
                        );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {item.name !== "Fábrica" &&
                            item.name !== "Prendas Devueltas" ? (
                              editButton
                            ) : (
                              <></>
                            )}
                            {checkDebtHistoryButton}
                            {checkInventoryButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <StoreUpdateModal
        show={showStoreEditModal}
        onCancel={onEditCancel}
        onSuccess={onEditSuccess}
        store={selectedStore}
      />
      <RecalculateModal
        show={showRecalculateModal}
        onCancel={onRecalculateCancel}
        onSuccess={onRecalculateSuccess}
      />
    </>
  );
};

export default StoreList;
