import jsPDF from "jspdf";

import Store from "../models/store";

const lungorLogo = require("../assets/img/lungorlogo.jpg");

const generateIncomeExpenseReportPDF = async (
  titlesMiddle: string[],
  titlesRight: string[],
  selectedStore: Store | undefined,
  sellingGenerated: number | undefined,
  sellingCost: number | undefined,
  sellingProfit: number | undefined,
  storeExpenses: number | undefined,
  factoryExpenses: number | undefined,
  allExpenses: number | undefined,
  toDepositProfit: number | undefined,
  grandProfit: number | undefined
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });

  doc.setFontSize(10);
  generateHeader(doc, titlesMiddle, titlesRight);

  doc.setFontSize(16);
  doc.text(`Resumen de Ganancia:`, 14, doc.internal.pageSize.height - 250);

  doc.setFontSize(14);
  doc.text(
    `Sucursal: ${
      selectedStore
        ? selectedStore.name
        : "No se seleccionó. Ganancia correspondera a todas las sucursales"
    }`,
    14,
    doc.internal.pageSize.height - 240
  );

  doc.text(`VENTAS`, 14, doc.internal.pageSize.height - 230);
  doc.setFontSize(12);

  doc.text(
    `Valor total facturado: ${sellingGenerated?.toLocaleString() || "-"} Gs`,
    14,
    doc.internal.pageSize.height - 220
  );

  doc.text(
    `Costo total de productos facturado: ${
      sellingCost?.toLocaleString() || "-"
    } Gs`,
    14,
    doc.internal.pageSize.height - 210
  );
  doc.text(
    `Ganancia total por ventas: ${sellingProfit?.toLocaleString() || "-"} Gs`,
    14,
    doc.internal.pageSize.height - 200
  );

  doc.setFontSize(14);
  doc.text(`GASTOS`, 14, doc.internal.pageSize.height - 190);
  doc.setFontSize(12);

  doc.text(
    `Gastos registrados en sucursal: ${
      storeExpenses?.toLocaleString() || "-"
    } Gs`,
    14,
    doc.internal.pageSize.height - 180
  );

  doc.text(
    `Gastos registrados en fábrica: ${
      factoryExpenses?.toLocaleString() || "-"
    } Gs`,
    14,
    doc.internal.pageSize.height - 170
  );
  doc.text(
    `Gastos Totales: ${allExpenses?.toLocaleString() || "-"} Gs`,
    14,
    doc.internal.pageSize.height - 160
  );

  doc.setFontSize(14);
  doc.text(`RESULTADO`, 14, doc.internal.pageSize.height - 150);
  doc.setFontSize(12);

  doc.text(
    `Ganancia Bruta (Ventas): ${sellingProfit?.toLocaleString() || "-"} Gs`,
    14,
    doc.internal.pageSize.height - 140
  );

  doc.text(
    `Ganancia en tienda (A Depositar): ${
      toDepositProfit?.toLocaleString() || "-"
    } Gs`,
    14,
    doc.internal.pageSize.height - 130
  );
  doc.setFontSize(13);
  doc.text(
    `GANANCIA NETA: ${grandProfit?.toLocaleString() || "-"} Gs`,
    14,
    doc.internal.pageSize.height - 120
  );

  doc.setFontSize(5);
  doc.text(`Página 1 de 1`, 14, doc.internal.pageSize.height - 10);

  const blob = doc.output("blob");
  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
};

const generateHeader = (
  doc: jsPDF,
  titlesMiddle: string[],
  titlesRight: string[]
) => {
  const x = 14;
  const y = 10;
  const width = doc.internal.pageSize.width - 28;
  const height = 30;
  const middleX = x + width / 2;

  doc.rect(x, y, width, height);

  const img = new Image();
  img.src = lungorLogo;
  doc.addImage(img, "JPEG", x + 5, y + 5, 35, 20);

  const totalTitlesMiddle = titlesMiddle.length;
  const totalTitlesRight = titlesRight.length;
  const totalTitles = Math.max(totalTitlesMiddle, totalTitlesRight);

  const verticalSpace = height / (totalTitles + 1);

  let middleTitleY = y + verticalSpace;

  titlesMiddle.forEach((title) => {
    const middleTitleWidth = doc.getTextWidth(title);
    const middleTitleX = middleX - middleTitleWidth / 2;
    doc.text(title, middleTitleX, middleTitleY);
    middleTitleY += verticalSpace;
  });

  doc.setFontSize(6);
  let rightTitleY = y + verticalSpace;

  const rightX = x + width;
  titlesRight.forEach((title) => {
    const rightTitleWidth = doc.getTextWidth(title);
    const rightTitleX = rightX - rightTitleWidth - 5;
    doc.text(title, rightTitleX, rightTitleY);
    rightTitleY += verticalSpace;
  });
};
export default generateIncomeExpenseReportPDF;
